<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="row m-0 main_header bold align-items-center text-right">
        <div class="col-12 pr-0 pr-md-4 pr-lg-5">
          <!-- <div class="pr-4" v-if="page === 'trips'">My Journeys <img src="../assets/images/menu_myjourney_active.png" class="icon"></div> -->
          <!-- <div class="pr-4" v-if="page === 'details'">{{selectedMenuItem.title}} <img :src="iconSrc(selectedMenuItem.icons.active)" class="icon"></div> -->
          <div class="pr-4">{{selectedMenuItem.title}} <img  v-if="selectedMenuItem.icons" :src="iconSrc(selectedMenuItem.icons.active)" class="icon"></div>
          <!-- <div class="pr-4" v-if="page === 'docs'">My Documents <img src="../assets/images/menu_mydocs_active.png" class="icon"></div> -->
          <!-- <div class="pr-4" v-if="page === 'planner'">My Travel Planner <img src="../assets/images/menu_mytp_active.png" class="icon"></div> -->
        </div>
      </div>
    </div>
    <div class="col-12 p-0" v-if="subPage">
      <div class="row m-0 align-items-center text-right bg_light">
        <div class="col-12 p-3 pr-4 pr-md-5 pr-lg-5">
          <div class="p-0 pr-lg-4 font5">{{subTitle}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSubItemFromList } from '@/helpers/menu'

export default {
  name: 'Header',
  props: {
    isDesktop: {
      type: Boolean,
      default: true
    },
    selectedMenuItem: {
      type: Object,
      required: true
    },
    subPage: {
      type: String,
      required: false
    }
  },
  components: {
  },
  data () {
    return {
      subTitle: null
    }
  },
  created () {
    if (this.subPage && this.selectedMenuItem && this.selectedMenuItem.subItems) {
      const sis = getSubItemFromList(this.selectedMenuItem.subItems, this.subPage)
      if (sis) {
        this.subTitle = sis.title
      }
    }
  },
  methods: {
    iconSrc (icon) {
      return require(`../assets/images/${icon}`)
    }
  }
}
</script>
<style scoped>
.main_header {
  height: 50px;
  position: sticky;
  top: 0;
  background-color: var(--beige);
  text-transform: capitalize;
}
.secondary_header {
  height: 25px;
  position: sticky;
  top: 50px;
  background-color: var(--secondarycolor);
  text-transform: capitalize;
  color: #fff;
}
.icon {
  height: 1.5rem;
  width: 1.5rem;
}
@media (min-width: 768px) {
  .main_header {
    height: 80px;
  }
  .secondary_header {
    height: 35px;
    top: 80px;
  }
  .icon {
    height: 42px;
    width: 42px;
  }
}
@media (min-width: 992px) {
  .main_header {
    height: 115px;
  }
  .secondary_header {
    height: 50px;
    top: 115px;
    background-color: #D1D3D4;
    text-transform: capitalize;
    color: var(--secondarycolor);
  }
  .icon {
    height: 25px;
    width: 25px;
  }
}
</style>
